@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary text-black;
  }

  .btn-filter{
    @apply w-40 py-3 m-3 bg-transparent rounded-full border transition duration-200;
  }

  .btn-filter:hover,
  .btn-filter.active {
    @apply bg-primary;
  }
}
